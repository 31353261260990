var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.user ? _vm.user.is_student : false)?_c('div',[(_vm.evaluation_not_started_yet)?[_c('div',{staticClass:"time-label start-time-label"},[_vm._v(" La evaluación comenzará el \""),_c('strong',[_vm._v(_vm._s(_vm._f("FormatToDateTime")(new Date(_vm.rubric.start_date))))]),_vm._v("\" Dentro de: ")]),_c('div',{staticClass:"time-card"},[(_vm.rubric)?_c('strong',[_c('Timer',{attrs:{"end_date":_vm.rubric.start_date}})],1):_vm._e()])]:(_vm.is_running)?[_c('div',{staticClass:"test-timer"},[_vm._v(" Tiempo restante "),_c('Timer',{attrs:{"end_date":_vm.rubric.due_date}})],1)]:[_c('div',{staticClass:"time-label left-time-label"},[_vm._v("Evaluación finalizada")])]],2):_vm._e(),_c('InstrumentHeader',{attrs:{"Instrument":_vm.rubric,"TestForm":false,"is_running":_vm.is_running,"is_student":_vm.user.is_student ? true : false,"is_professor":_vm.user.is_professor ? true : false,"is_finished":true,"instrumentStat":_vm.sectionStat,"instrumentScore":_vm.observation_instrument_score ? _vm.observation_instrument_score : null,"allows_crud":_vm.allows_crud}}),_c('div',{staticClass:"row-div"},[_c('div',{staticClass:"edit-div row-div"},[(_vm.allows_crud)?_c('div',{staticClass:"m-0"},[_c('b-button',{staticClass:"mb-2 mr-2",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.$bvModal.show(`modal-update-rubric-${_vm.rubric.id}`)}}},[_vm._v("Editar Instrumento")]),_c('b-button',{staticClass:"mb-2 mr-2",attrs:{"size":"sm","variant":"primary","disabled":_vm.totalWeighing >= 100,"title":_vm.totalWeighing >= 100
              ? 'Ha alcanzado el nivel de ponderación máximo.'
              : ''},on:{"click":function($event){return _vm.$bvModal.show(`modal-create-rubric-instance-${_vm.rubric.id}`)}}},[_vm._v("Agregar "+_vm._s(_vm.$getVisibleNames("manual.dimension", false, "Dimensión")))]),_c('b-button',{staticClass:"mb-2 mr-2",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.$bvModal.show(`modal-create-rubric-achievement-${_vm.rubric.id}`)}}},[_vm._v("Agregar "+_vm._s(_vm.$getVisibleNames( "evaluations.observationachievement", false, "Nivel de Logro" )))])],1):_vm._e(),(!_vm.user.is_student)?_c('b-button',{staticClass:"mb-2",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.$bvModal.show(
            `modal-specification-table-${_vm.observation_instrument_id}`
          )}}},[_vm._v("Tabla de Especificaciones")]):_vm._e()],1),(_vm.allows_crud)?_c('div',{staticClass:"delete-div"},[_c('b-button',{staticClass:"mb-2",attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.deleteRubric}},[_vm._v("Eliminar Instrumento")])],1):_vm._e()]),_c('b-modal',{attrs:{"id":`modal-specification-table-${_vm.observation_instrument_id}`,"title":"Tabla de Especificaciones","size":"xl","hide-footer":"","modal-class":"custom-modal"}},[_c('div',[_c('SpecificationTableView',{attrs:{"QuestionTest":_vm.rubric,"evaluation_id":_vm.rubric.evaluation,"allows_crud":_vm.allows_crud,"observation_table":true},on:{"specificationTableDimension":_vm.specificationTableDimension,"printerEvent":_vm.printStateChanged}})],1)]),(
      (_vm.rubric && !_vm.user.is_student) ||
      (_vm.rubric && _vm.rubric.allow_access_student && _vm.evaluation_not_started_yet) ||
      (_vm.rubric && _vm.is_running) ||
      (_vm.rubric && !_vm.is_running && !_vm.evaluation_not_started_yet)
    )?[(_vm.observation_instrument_score && _vm.user.is_student)?[(_vm.rubric && _vm.is_running && _vm.user.is_student)?_c('b-button',{staticClass:"open-file-selector mt-1 mb-3",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show('modal-select-file')}}},[_vm._v("Subir Archivo")]):_vm._e(),_c('b-modal',{attrs:{"id":"modal-select-file","title":"Seleccionar Archivos","hide-footer":""}},[_c('FileSelector',{on:{"created":_vm.slotCreatedFile}})],1),(
          _vm.observation_instrument_score.attachments.length > 0 &&
          ((_vm.rubric && !_vm.is_running && !_vm.evaluation_not_started_yet) ||
            (_vm.rubric && _vm.is_running))
        )?[_c('FileContainer',{attrs:{"Files":_vm.observation_instrument_score.attachments,"allows_crud":_vm.is_running ? true : false},on:{"deleteFile":_vm.slotDeleteFile}})]:_vm._e()]:_vm._e(),_c('table',[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"4"}}),_c('th',{attrs:{"colspan":_vm.rubricAchievementList.length > 0
                ? _vm.rubricAchievementList.length
                : 1}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "evaluations.observationachievement", true, "Niveles de Logro" ))+" ")]),(_vm.allows_crud)?_c('th'):_vm._e()]),_c('tr',[_c('th',{staticStyle:{"width":"2.5%"}},[_vm._v("N°")]),_c('th',{staticStyle:{"width":"15%"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("manual.dimension", true, "Dimensión"))+" ")]),_c('th',{staticStyle:{"width":"15%"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.criterio_de_evaluacion", true, "Criterios de Evaluación" ))+" ")]),_c('th',[_vm._v("Ponderación")]),_vm._l((_vm.rubricAchievementList),function(rubric_achievement){return _c('th',{key:rubric_achievement.id},[_vm._v(" "+_vm._s(rubric_achievement.text)+" "),_c('br'),_vm._v(" "+_vm._s(rubric_achievement.score)+" puntos "),(_vm.allows_crud)?_c('button-edit',{attrs:{"title":"Editar Escala de Valoración"},on:{"click":function($event){return _vm.$bvModal.show(
                  `modal-update-rubric-achievement-${rubric_achievement.id}`
                )}}}):_vm._e(),(_vm.allows_crud)?_c('b-modal',{attrs:{"id":`modal-update-rubric-achievement-${rubric_achievement.id}`,"title":"Editar Escala de Valoración para la Rúbrica","hide-footer":""}},[_c('AchievementForm',{attrs:{"observation_instrument_id":_vm.rubric.id,"RubricAchievement":rubric_achievement,"show_delete_button":true,"show_title":false},on:{"updated":function($event){return _vm.$bvModal.hide(
                    `modal-update-rubric-achievement-${rubric_achievement.id}`
                  )},"deleted":function($event){return _vm.$bvModal.hide(
                    `modal-update-rubric-achievement-${rubric_achievement.id}`
                  )}}})],1):_vm._e()],1)}),(_vm.rubricAchievementList.length == 0)?_c('th',[_vm._v(" No tiene "+_vm._s(_vm.$getVisibleNames( "evaluations.observationachievement", true, "Niveles de Logro" ))+" ")]):_vm._e(),(_vm.allows_crud)?_c('th',{staticStyle:{"width":"5%"}}):_vm._e()],2)]),_c('draggable',{attrs:{"disabled":!_vm.allows_crud,"tag":"tbody","handle":".handle"},model:{value:(_vm.rubricInstanceList),callback:function ($$v) {_vm.rubricInstanceList=$$v},expression:"rubricInstanceList"}},[_vm._l((_vm.rubricInstanceList),function(observation_instance){return [_c('tr',{key:`${observation_instance.id}`},[_c('td',{staticClass:"handle"},[(_vm.allows_crud)?_c('b-icon',{staticClass:"handle-icon",attrs:{"icon":"arrows-expand","scale":"1.2"}}):_vm._e(),_vm._v(" "+_vm._s(observation_instance.order)+" ")],1),_c('td',[_c('div',[_c('SentenceContainer',{attrs:{"Sentence":observation_instance,"boundedTextContainer":true,"Large":18}})],1)]),_c('td',[(
                  observation_instance.evaluation_criterias_macro.length >
                    0 ||
                  observation_instance.evaluation_criterias_micro.length > 0
                )?[_c('EvaluationCriterias',{attrs:{"key_id":observation_instance.id,"evaluation_id":_vm.rubric.evaluation,"ECMacro":observation_instance.evaluation_criterias_macro,"ECMicro":observation_instance.evaluation_criterias_micro,"Large":18}})]:[_c('span',[_vm._v("Esta "+_vm._s(_vm.$getVisibleNames("manual.dimension", false, "Dimensión"))+" no tiene asociado un criterio de evaluación.")])]],2),_c('td',[_vm._v(_vm._s(observation_instance.weighing)+"%")]),_vm._l((_vm.rubricAchievementList),function(rubric_achievement){return _c('td',{key:rubric_achievement.id},[_c('DescriptorCell',{attrs:{"observation_instance_id":observation_instance.id,"observation_achievement_id":rubric_achievement.id,"allows_crud":_vm.allows_crud}})],1)}),(_vm.rubricAchievementList.length == 0)?_c('td'):_vm._e(),(_vm.allows_crud)?_c('td',[_c('button-edit',{on:{"click":function($event){return _vm.$bvModal.show(
                    `modal-update-rubric-instance-${observation_instance.id}`
                  )}}}),_c('b-modal',{attrs:{"id":`modal-update-rubric-instance-${observation_instance.id}`,"title":'Modificar ' +
                  _vm.$getVisibleNames('manual.dimension', false, 'Dimensión'),"hide-footer":"","size":"xl"}},[_c('InstanceForm',{attrs:{"observation_instrument_id":_vm.rubric.id,"ObservationInstance":observation_instance,"evaluation_id":_vm.rubric.evaluation,"max_weighing":Number(
                      100 - _vm.totalWeighing + observation_instance.weighing
                    ),"show_delete_button":true},on:{"updated":function($event){return _vm.$bvModal.hide(
                      `modal-update-rubric-instance-${observation_instance.id}`
                    )},"deleted":function($event){return _vm.$bvModal.hide(
                      `modal-update-rubric-instance-${observation_instance.id}`
                    )}}})],1)],1):_vm._e()],2)]})],2),_c('tfoot',[_c('tr',[_vm._m(0),_c('td',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",modifiers:{"v-secondary":true,"noninteractive":true}}],style:({ color: _vm.totalWeighing != 100 ? 'red' : '' }),attrs:{"title":_vm.totalWeighing != 100
                ? 'La ponderación total debe ser igual a 100.'
                : ''}},[_c('strong',[_vm._v(_vm._s(_vm.totalWeighing)+"%")])]),_vm._l((_vm.rubricAchievementList),function(rubric_achievement){return _c('td',{key:rubric_achievement.id})}),(_vm.rubricAchievementList.length == 0)?_c('td'):_vm._e(),(_vm.allows_crud)?_c('td'):_vm._e()],2)])],1),_c('b-modal',{attrs:{"id":`modal-update-rubric-${_vm.rubric.id}`,"title":`Editar ${
        _vm.instrumentType ? _vm.instrumentType.value : 'Instrumento'
      }`,"size":"lg","hide-footer":""}},[_c('ObservationInstrumentForm',{attrs:{"TestForm":false,"Rubric":_vm.rubric,"evaluation_id":_vm.rubric.evaluation},on:{"updated":function($event){return _vm.$bvModal.hide(`modal-update-rubric-${_vm.rubric.id}`)}}})],1),_c('b-modal',{attrs:{"id":`modal-create-rubric-instance-${_vm.rubric.id}`,"title":'Crear ' + _vm.$getVisibleNames('manual.dimension', false, 'Dimensión'),"hide-footer":"","size":"xl"}},[_c('InstanceForm',{attrs:{"observation_instrument_id":_vm.rubric.id,"evaluation_id":_vm.rubric.evaluation,"max_weighing":Number(100 - _vm.totalWeighing),"default_order":_vm.rubricInstanceList.length + 1},on:{"created":function($event){return _vm.$bvModal.hide(`modal-create-rubric-instance-${_vm.rubric.id}`)}}})],1),_c('b-modal',{attrs:{"id":`modal-create-rubric-achievement-${_vm.rubric.id}`,"title":"Crear Escala de Valoración","hide-footer":""}},[_c('AchievementForm',{attrs:{"observation_instrument_id":_vm.rubric.id,"show_title":false},on:{"created":function($event){return _vm.$bvModal.hide(`modal-create-rubric-achievement-${_vm.rubric.id}`)}}})],1)]:[_vm._v(" Este instrumento aún no ha comenzado. ")]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"3"}},[_c('strong',[_vm._v("Totales")])])
}]

export { render, staticRenderFns }