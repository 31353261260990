<template>
  <div>
    <div v-if="user ? user.is_student : false">
      <template v-if="evaluation_not_started_yet">
        <div class="time-label start-time-label">
          La evaluación comenzará el "<strong>{{
            new Date(rubric.start_date) | FormatToDateTime
          }}</strong
          >" Dentro de:
        </div>
        <div class="time-card">
          <strong v-if="rubric">
            <Timer :end_date="rubric.start_date" />
          </strong>
        </div>
      </template>
      <template v-else-if="is_running">
        <div class="test-timer">
          Tiempo restante
          <Timer :end_date="rubric.due_date" />
        </div>
      </template>
      <template v-else>
        <div class="time-label left-time-label">Evaluación finalizada</div>
      </template>
    </div>

    <InstrumentHeader
      :Instrument="rubric"
      :TestForm="false"
      :is_running="is_running"
      :is_student="user.is_student ? true : false"
      :is_professor="user.is_professor ? true : false"
      :is_finished="true"
      :instrumentStat="sectionStat"
      :instrumentScore="
        observation_instrument_score ? observation_instrument_score : null
      "
      :allows_crud="allows_crud"
    ></InstrumentHeader>
    <div class="row-div">
      <div class="edit-div row-div">
        <div class="m-0" v-if="allows_crud">
          <b-button
            @click="$bvModal.show(`modal-update-rubric-${rubric.id}`)"
            size="sm"
            class="mb-2 mr-2"
            variant="primary"
            >Editar Instrumento</b-button
          >
          <b-button
            @click="$bvModal.show(`modal-create-rubric-instance-${rubric.id}`)"
            size="sm"
            class="mb-2 mr-2"
            variant="primary"
            :disabled="totalWeighing >= 100"
            :title="
              totalWeighing >= 100
                ? 'Ha alcanzado el nivel de ponderación máximo.'
                : ''
            "
            >Agregar
            {{
              $getVisibleNames("manual.dimension", false, "Dimensión")
            }}</b-button
          >
          <b-button
            @click="
              $bvModal.show(`modal-create-rubric-achievement-${rubric.id}`)
            "
            size="sm"
            class="mb-2 mr-2"
            variant="primary"
            >Agregar
            {{
              $getVisibleNames(
                "evaluations.observationachievement",
                false,
                "Nivel de Logro"
              )
            }}</b-button
          >
        </div>
        <b-button
          v-if="!user.is_student"
          size="sm"
          class="mb-2"
          variant="primary"
          @click="
            $bvModal.show(
              `modal-specification-table-${observation_instrument_id}`
            )
          "
          >Tabla de Especificaciones</b-button
        >
      </div>
      <div class="delete-div" v-if="allows_crud">
        <b-button @click="deleteRubric" size="sm" class="mb-2" variant="danger"
          >Eliminar Instrumento</b-button
        >
      </div>
    </div>
    <b-modal
      :id="`modal-specification-table-${observation_instrument_id}`"
      title="Tabla de Especificaciones"
      size="xl"
      hide-footer
      modal-class="custom-modal"
    >
      <div>
        <SpecificationTableView
          :QuestionTest="rubric"
          :evaluation_id="rubric.evaluation"
          :allows_crud="allows_crud"
          :observation_table="true"
          @specificationTableDimension="specificationTableDimension"
          @printerEvent="printStateChanged"
        ></SpecificationTableView>
      </div>
      <!-- @stateChanged="slotStateChanged" -->
    </b-modal>
    <template
      v-if="
        (rubric && !user.is_student) ||
        (rubric && rubric.allow_access_student && evaluation_not_started_yet) ||
        (rubric && is_running) ||
        (rubric && !is_running && !evaluation_not_started_yet)
      "
    >
      <template v-if="observation_instrument_score && user.is_student">
        <b-button
          v-if="rubric && is_running && user.is_student"
          @click="$bvModal.show('modal-select-file')"
          size="sm"
          class="open-file-selector mt-1 mb-3"
          >Subir Archivo</b-button
        >
        <b-modal
          id="modal-select-file"
          title="Seleccionar Archivos"
          hide-footer
        >
          <FileSelector @created="slotCreatedFile"></FileSelector>
        </b-modal>
        <template
          v-if="
            observation_instrument_score.attachments.length > 0 &&
            ((rubric && !is_running && !evaluation_not_started_yet) ||
              (rubric && is_running))
          "
        >
          <FileContainer
            :Files="observation_instrument_score.attachments"
            :allows_crud="is_running ? true : false"
            @deleteFile="slotDeleteFile"
          ></FileContainer>
        </template>
      </template>
      <table>
        <thead>
          <tr>
            <th colspan="4"></th>
            <th
              :colspan="
                rubricAchievementList.length > 0
                  ? rubricAchievementList.length
                  : 1
              "
            >
              {{
                $getVisibleNames(
                  "evaluations.observationachievement",
                  true,
                  "Niveles de Logro"
                )
              }}
            </th>
            <th v-if="allows_crud"></th>
          </tr>
          <tr>
            <th style="width: 2.5%">N°</th>
            <th style="width: 15%">
              {{ $getVisibleNames("manual.dimension", true, "Dimensión") }}
            </th>
            <th style="width: 15%">
              {{
                $getVisibleNames(
                  "manual.criterio_de_evaluacion",
                  true,
                  "Criterios de Evaluación"
                )
              }}
            </th>
            <th>Ponderación</th>
            <th
              v-for="rubric_achievement in rubricAchievementList"
              :key="rubric_achievement.id"
            >
              {{ rubric_achievement.text }}
              <br />
              {{ rubric_achievement.score }} puntos
              <button-edit
                v-if="allows_crud"
                @click="
                  $bvModal.show(
                    `modal-update-rubric-achievement-${rubric_achievement.id}`
                  )
                "
                title="Editar Escala de Valoración"
              ></button-edit>
              <b-modal
                v-if="allows_crud"
                :id="`modal-update-rubric-achievement-${rubric_achievement.id}`"
                title="Editar Escala de Valoración para la Rúbrica"
                hide-footer
              >
                <AchievementForm
                  :observation_instrument_id="rubric.id"
                  :RubricAchievement="rubric_achievement"
                  :show_delete_button="true"
                  :show_title="false"
                  @updated="
                    $bvModal.hide(
                      `modal-update-rubric-achievement-${rubric_achievement.id}`
                    )
                  "
                  @deleted="
                    $bvModal.hide(
                      `modal-update-rubric-achievement-${rubric_achievement.id}`
                    )
                  "
                ></AchievementForm>
              </b-modal>
            </th>
            <th v-if="rubricAchievementList.length == 0">
              No tiene
              {{
                $getVisibleNames(
                  "evaluations.observationachievement",
                  true,
                  "Niveles de Logro"
                )
              }}
            </th>
            <th style="width: 5%" v-if="allows_crud"></th>
          </tr>
        </thead>
        <!-- <template v-for="criteria in evaluationCriteriaList"> -->
        <draggable
          v-model="rubricInstanceList"
          :disabled="!allows_crud"
          tag="tbody"
          handle=".handle"
        >
          <template v-for="observation_instance in rubricInstanceList">
            <!-- <template v-for="(rubric_instance, index) in observation_instance"> -->
            <tr :key="`${observation_instance.id}`">
              <td class="handle">
                <b-icon
                  v-if="allows_crud"
                  class="handle-icon"
                  icon="arrows-expand"
                  scale="1.2"
                ></b-icon>
                {{ observation_instance.order }}
              </td>
              <td>
                <div>
                  <SentenceContainer
                    :Sentence="observation_instance"
                    :boundedTextContainer="true"
                    :Large="18"
                  ></SentenceContainer>
                </div>
              </td>
              <td>
                <template
                  v-if="
                    observation_instance.evaluation_criterias_macro.length >
                      0 ||
                    observation_instance.evaluation_criterias_micro.length > 0
                  "
                >
                  <EvaluationCriterias
                    :key_id="observation_instance.id"
                    :evaluation_id="rubric.evaluation"
                    :ECMacro="observation_instance.evaluation_criterias_macro"
                    :ECMicro="observation_instance.evaluation_criterias_micro"
                    :Large="18"
                  ></EvaluationCriterias>
                </template>
                <template v-else>
                  <!-- <span class="text-danger"
                    >Esta dimensión no tiene asociado un criterio de
                    evaluación.</span
                  > -->
                  <span
                    >Esta
                    {{
                      $getVisibleNames("manual.dimension", false, "Dimensión")
                    }}
                    no tiene asociado un criterio de evaluación.</span
                  >
                </template>
              </td>
              <td>{{ observation_instance.weighing }}%</td>
              <td
                v-for="rubric_achievement in rubricAchievementList"
                :key="rubric_achievement.id"
              >
                <DescriptorCell
                  :observation_instance_id="observation_instance.id"
                  :observation_achievement_id="rubric_achievement.id"
                  :allows_crud="allows_crud"
                ></DescriptorCell>
              </td>
              <td v-if="rubricAchievementList.length == 0"></td>
              <td v-if="allows_crud">
                <button-edit
                  @click="
                    $bvModal.show(
                      `modal-update-rubric-instance-${observation_instance.id}`
                    )
                  "
                ></button-edit>
                <b-modal
                  :id="`modal-update-rubric-instance-${observation_instance.id}`"
                  :title="
                    'Modificar ' +
                    $getVisibleNames('manual.dimension', false, 'Dimensión')
                  "
                  hide-footer
                  size="xl"
                >
                  <InstanceForm
                    :observation_instrument_id="rubric.id"
                    :ObservationInstance="observation_instance"
                    :evaluation_id="rubric.evaluation"
                    :max_weighing="
                      Number(
                        100 - totalWeighing + observation_instance.weighing
                      )
                    "
                    :show_delete_button="true"
                    @updated="
                      $bvModal.hide(
                        `modal-update-rubric-instance-${observation_instance.id}`
                      )
                    "
                    @deleted="
                      $bvModal.hide(
                        `modal-update-rubric-instance-${observation_instance.id}`
                      )
                    "
                  ></InstanceForm>
                </b-modal>
              </td>
            </tr>
            <!-- </template> -->
          </template>
        </draggable>
        <!-- </template> -->
        <tfoot>
          <tr>
            <td colspan="3">
              <strong>Totales</strong>
            </td>
            <td
              :title="
                totalWeighing != 100
                  ? 'La ponderación total debe ser igual a 100.'
                  : ''
              "
              v-b-tooltip.v-secondary.noninteractive
              v-bind:style="{ color: totalWeighing != 100 ? 'red' : '' }"
            >
              <strong>{{ totalWeighing }}%</strong>
            </td>
            <td
              v-for="rubric_achievement in rubricAchievementList"
              :key="rubric_achievement.id"
            ></td>
            <td v-if="rubricAchievementList.length == 0"></td>
            <td v-if="allows_crud"></td>
          </tr>
        </tfoot>
      </table>
      <!-- Modals -->
      <b-modal
        :id="`modal-update-rubric-${rubric.id}`"
        :title="`Editar ${
          instrumentType ? instrumentType.value : 'Instrumento'
        }`"
        size="lg"
        hide-footer
      >
        <ObservationInstrumentForm
          :TestForm="false"
          :Rubric="rubric"
          :evaluation_id="rubric.evaluation"
          @updated="$bvModal.hide(`modal-update-rubric-${rubric.id}`)"
        ></ObservationInstrumentForm>
      </b-modal>
      <b-modal
        :id="`modal-create-rubric-instance-${rubric.id}`"
        :title="
          'Crear ' + $getVisibleNames('manual.dimension', false, 'Dimensión')
        "
        hide-footer
        size="xl"
      >
        <InstanceForm
          :observation_instrument_id="rubric.id"
          :evaluation_id="rubric.evaluation"
          :max_weighing="Number(100 - totalWeighing)"
          :default_order="rubricInstanceList.length + 1"
          @created="$bvModal.hide(`modal-create-rubric-instance-${rubric.id}`)"
        ></InstanceForm>
      </b-modal>
      <b-modal
        :id="`modal-create-rubric-achievement-${rubric.id}`"
        title="Crear Escala de Valoración"
        hide-footer
      >
        <AchievementForm
          :observation_instrument_id="rubric.id"
          :show_title="false"
          @created="
            $bvModal.hide(`modal-create-rubric-achievement-${rubric.id}`)
          "
        ></AchievementForm>
      </b-modal>
    </template>
    <template v-else> Este instrumento aún no ha comenzado. </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
import draggable from "vuedraggable";

export default {
  name: "ObservationInstrument",
  components: {
    draggable,
    ObservationInstrumentForm: () => import("./ObservationInstrumentForm"),
    InstanceForm: () => import("./InstanceForm"),
    AchievementForm: () => import("./AchievementForm"),
    DescriptorCell: () => import("./DescriptorCell"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    EvaluationCriterias: () => import("../EvaluationCriterias.vue"),
    InstrumentHeader: () => import("../InstrumentHeader.vue"),
    Timer: () => import("../../reusable/Timer.vue"),
    FileSelector: () => import("@/components/reusable/FileSelector"),
    FileContainer: () => import("@/components/reusable/FileContainer"),
    SpecificationTableView: () =>
      import("../SpecificationTable/SpecificationTableView"),
  },
  props: {
    observation_instrument_id: {
      type: Number,
      required: true,
    },
    section_evaluation_stats: {
      type: Array,
      default: null,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rubric_instance: null,
      is_running: false,
      evaluation_not_started_yet: false,
      time_to_start: null,
      time_to_end: null,
      time_elapsed_to_start: null,
      time_elapsed_to_end: null,
      observation_instrument_score: null,
      print_state: false,
    };
  },
  computed: {
    ...mapGetters({
      rubrics: names.OBSERVATION_INSTRUMENTS,
      rubricAchievements: names.OBSERVATION_ACHIEVEMENTS,
      rubricInstances: names.OBSERVATION_INSTANCES,
      rubricDescriptors: names.OBSERVATION_DESCRIPTORS,
      // evaluationCriterias: names.EVALUATION_CRITERIAS,
      evaluations: names.EVALUATIONS,
      // studyUnits: names.STUDY_UNITS,
      instrumentTypes: names.EVALUATION_INSTRUMENTS,
      user: "getUser",
    }),
    rubric() {
      return this.rubrics.find((x) => x.id == this.observation_instrument_id);
    },
    sectionStat() {
      if (this.section_evaluation_stats == null) return null;
      else
        return this.section_evaluation_stats.find(
          (x) =>
            x.instrument_id == this.observation_instrument_id &&
            x.instrument_type == "observation_instrument"
        );
    },
    rubricAchievementList() {
      return this.rubricAchievements
        .filter(
          (x) => x.observation_instrument == this.observation_instrument_id
        )
        .sort(function (a, b) {
          return a.score > b.score ? -1 : 1;
        });
    },
    rubricInstanceList: {
      get() {
        let list = this.rubricInstances.filter(
          (x) => x.observation_instrument == this.observation_instrument_id
        );
        list.sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) element.order = order;
          order += 1;
        });
        return list;
      },
      set(list) {
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) {
            element.order = order;
            if (!isNaN(element.id)) {
              let payload = {
                observation_instance_id: element.id,
                item: {
                  order: element.order,
                },
              };
              this.$store.dispatch(names.PATCH_OBSERVATION_INSTANCE, payload);
            }
          }
          order += 1;
        });
      },
    },

    // return this.rubricInstances
    //   .filter(
    //     (x) => x.observation_instrument == this.observation_instrument_id
    //   )
    //   .sort(function (a, b) {
    //     if (a.id > b.id) return 1;
    //     if (a.id < b.id) return -1;
    //     return 0;
    //   });

    rubricDescriptorList() {
      let list = [];
      this.rubricInstanceList.forEach((x) => {
        list.push(x.id);
      });
      return this.rubricDescriptors.filter((x) =>
        x.observation_instance.includes(list)
      );
    },
    totalWeighing() {
      let weighing = 0;
      this.rubricInstanceList.forEach((element) => {
        weighing += Number(element.weighing);
      });
      return weighing;
    },
    evaluation() {
      if (!this.rubric) return null;
      return this.evaluations.find((x) => x.id == this.rubric.evaluation);
    },
    instrumentType() {
      if (!this.evaluation) return null;
      return this.instrumentTypes.find((x) => x.id == this.evaluation.type);
    },
  },
  methods: {
    slotCreatedFile(file) {
      if (file) {
        this.observation_instrument_score.attachments.push(file.id);
        this.$restful.Put(
          `/evaluation/observation-instrument-score/${this.observation_instrument_score.id}/`,
          {
            attachments: this.observation_instrument_score.attachments,
          }
        );
      }
      this.$bvModal.hide("modal-select-file");
    },
    slotDeleteFile(file_id) {
      const index =
        this.observation_instrument_score.attachments.indexOf(file_id);
      if (index != -1) {
        this.observation_instrument_score.attachments.splice(index, 1);
        this.$restful.Put(
          `/evaluation/observation-instrument-score/${this.observation_instrument_score.id}/`,
          {
            attachments: this.observation_instrument_score.attachments,
          }
        );
      }
    },
    deleteRubric() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar la ${this.instrumentType.value}?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_OBSERVATION_INSTRUMENT, this.rubric.id)
            .then(() => {
              this.$emit("deleted", this.rubric);
              toast("Rúbrica eliminada.");
            });
        }
      });
    },
    fetchObservationInstrumentScore() {
      if (this.user.is_student) {
        this.$restful
          .Get(
            `/evaluation/observation-instrument-score/?observation_instrument=${this.observation_instrument_id}&student=${this.user.id}`
          )
          .then((response) => {
            this.observation_instrument_score = response.find(
              (x) =>
                x.observation_instrument == this.observation_instrument_id &&
                x.student == this.user.id
            );
          });
      }
    },
    checkTimes() {
      if (this.time_to_start || this.time_to_end) return;
      clearTimeout();
      this.time_to_start = null;
      this.time_to_end = null;
      this.time_elapsed_to_start = null;
      this.time_elapsed_to_end = null;
      if (
        this.evaluation &&
        this.$moment(this.rubric.due_date) > this.$moment() &&
        this.$moment(this.rubric.start_date) < this.$moment()
      ) {
        let current_date = this.$moment();
        if (!this.$moment(this.rubric.start_date)) {
          return;
        }
        let start_date = this.$moment(this.rubric.start_date);
        if (start_date > current_date) {
          if (start_date.diff(current_date) < 24 * 60 * 60 * 1000)
            this.time_to_start = start_date.diff(current_date);
        } else {
          this.is_running = true;
          this.key_component += 1;
        }
        if (!this.$moment(this.rubric.due_date)) {
          return;
        }
        let due_date = this.$moment(this.rubric.due_date);
        if (due_date > current_date && this.is_running) {
          this.time_to_end = due_date - current_date;
        } else {
          this.is_running = false;
          this.key_component += 1;
        }
      } else if (
        this.evaluation &&
        this.$moment(this.rubric.start_date) > this.$moment()
      ) {
        this.evaluation_not_started_yet = true;
      }
    },
    specificationTableDimension() {
      this.key_component += 1;
    },
    printStateChanged(print_state) {
      this.print_state = print_state;
    },
  },
  mounted() {
    this.checkTimes();
  },
  created() {
    this.fetchObservationInstrumentScore();
    this.$store.dispatch(names.FETCH_TAXONOMIES);
    this.$store
      .dispatch(
        names.FETCH_OBSERVATION_INSTANCES,
        this.observation_instrument_id
      )
      .then((response) => {
        this.rubric_instance = response;
      });
    this.$store
      .dispatch(
        names.FETCH_OBSERVATION_INSTRUMENT,
        this.observation_instrument_id
      )
      .then((rubric) => {
        this.$store.dispatch(names.FETCH_EVALUATION, rubric.evaluation);
        rubric.attachments.forEach((element) => {
          this.$store.dispatch(names.FETCH_FILE, element);
        });
      });
    this.$store
      .dispatch(names.FETCH_SECTION_EVALUATIONS, this.evaluation_id)
      .then((response) => {
        response.forEach((section_evaluation) => {
          section_evaluation.evaluation_criterias_micro.forEach(
            (ra_micro_id) => {
              this.$store.dispatch(
                names.FETCH_EVALUATION_CRITERIA,
                ra_micro_id
              );
            }
          );
          section_evaluation.evaluation_criterias_macro.forEach(
            (ra_macro_id) => {
              this.$store.dispatch(
                names.FETCH_EVALUATION_CRITERIA_MACRO,
                ra_macro_id
              );
            }
          );
        });
      });
  },
  watch: {
    time_to_start() {
      if (this.time_to_start && this.time_to_start > 0) {
        this.time_elapsed_to_start = this.time_to_start;
        let interval = 1000;
        if (this.time_elapsed_to_start > 60 * 60 * 1000)
          interval = 60 * 60 * 1000;
        clearTimeout();
        setInterval(() => {
          this.time_elapsed_to_start -= interval;
          if (this.time_elapsed_to_start < 0) {
            clearInterval();
            this.time_elapsed_to_start = null;
            this.checkTimes();
            this.checkPermissions();
          }
        }, interval);
      } else {
        this.time_to_start = null;
      }
    },
    time_to_end() {
      if (this.time_to_end && this.time_to_end > 0) {
        this.time_elapsed_to_end = this.time_to_end;
        clearTimeout();
        setInterval(() => {
          this.time_elapsed_to_end -= 1000;
          if (this.time_elapsed_to_end < 0) {
            clearInterval();
            this.time_elapsed_to_end = null;
            this.is_running = false;
          }
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
.container-rubric {
  background-color: var(--primary-color);
  border-top: 1px black solid;
  border-left: 1px black solid;
  border-right: 1px black solid;
}
.title-rubric {
  border-bottom: 1px black solid;
}
table {
  width: 100%;
  font-size: var(--secondary-font-size);
}
td,
th {
  border: 1px solid black;
}
th {
  font-size: var(--secondary-font-size);
  font-weight: bold;
  background-color: var(--primary-color);
  color: white;
}
tfoot {
  background-color: var(--secondary-color);
  font-weight: bold;
}
.row-div {
  display: flex;
  flex-direction: row;
}
.delete-div {
  display: flex;
  flex-direction: row-reverse;
  width: 50%;
}
.edit-div {
  display: flex;
  flex-direction: row;
  width: 50%;
}
.time-label {
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
}
.left-time-label {
  background-color: rgb(235, 70, 70);
}
.start-time-label {
  background-color: green;
}
.time-card {
  font-size: 20px;
}
.time-cell {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.test-timer {
  width: 99%;
  margin-inline: 0.5%;
  background: rgba(153, 201, 216, 0.5);
  font-weight: bold;
}
.btn-specification-table {
  /* width: 15em;
  float: left; */
}
.hanlde-icon {
  transform: translateX(-20%);
}
.handle {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.handle:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>